<template>
  <Navigation />
</template>

<script>
import Navigation from "@/modules/navigation/components/Navigation"

export default {
  name: "Home",
  components: {Navigation}
}
</script>
